export function setSeoMeta(seoMetaTags: any, config: any, route: any) {
    if (!seoMetaTags) {
        return;
    }

    useHead({
        link: [
            {
                rel: 'canonical',
                href: seoMetaTags[0]?.canonical ?? process.env[process.env.PROJECT_CODE + '_SITE_URL'] + route.path,
            },
        ],
    });

    useSeoMeta({
        title: () => seoMetaTags[0]?.title ?? '',
        description: () => seoMetaTags[0]?.description ?? '',
        robots: () => (config.STORYBLOK_VERSION === 'published' ? seoMetaTags[0]?.robots : 'nofollow,noindex'),
        ogTitle: () => seoMetaTags[0]?.title ?? '',
        ogDescription: () => seoMetaTags[0]?.description ?? '',
        ogImage: () => seoMetaTags[0]?.og_image?.filename ?? '',
        ogType: () => seoMetaTags[0]?.og_type ?? '',
        ogUrl: () => seoMetaTags[0]?.og_url ?? '',
        twitterCard: () => seoMetaTags[0]?.twitter_card ?? '',
        twitterSite: () => seoMetaTags[0]?.twitter_site ?? '',
        twitterTitle: () => seoMetaTags[0]?.twitter_title ?? '',
        twitterDescription: () => seoMetaTags[0]?.twitter_description ?? '',
        twitterCreator: () => seoMetaTags[0]?.twitter_creator ?? '',
        twitterImage: () => seoMetaTags[0]?.twitter_image?.filename ?? '',
    });
}
